<template>
  <div>
    <div v-if="addToHomeScreenPopup" class="add-to-homescreen">
      <div>
        <img :src="company.logoURL" />
        <div>
          <h1>{{ companyName }}</h1>
          <br />
          <p>
            <component v-if="isIos" v-bind:is="textIos" v-bind="$props" />
            <component v-else v-bind:is="textAndroid" v-bind="$props" />
          </p>
        </div>
        <a class="close" @click="close">
          <i class="material-icons">close</i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  created() {
    if (this.shouldShowaddToHomeScreenPopup) {
      this.addToHomeScreenPopup = true;
    }
  },
  computed: {
    ...mapGetters('settings', ['company']),
    textIos() {
      let template =
        '<span>' +
        this.$t('notifications.addToHomescreen', {
          icon: `<img
            src="/img/icons/share.png"
            class="img-fluid mx-1"
            width="14px"
          >`
        }) +
        '</span>';

      return {
        template,
        props: this.$options.props
      };
    },
    textAndroid() {
      let template =
        '<span>' +
        this.$t('notifications.addToHomescreenAndroid', {
          icon: `<i
            class="material-icons android"
          >more_vert</i>`
        }) +
        '</span>';

      return {
        template,
        props: this.$options.props
      };
    },
    companyName() {
      let onboardingPageName = window.localStorage.onboardingPageName;
      if (onboardingPageName) {
        return onboardingPageName;
      }
      return (
        window.location.hostname && window.location.hostname.split('.')[0] + ` ${this.$t('template.boarding')} app`
      );
    },
    isIos() {
      return /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
    },
    isInStandaloneMode() {
      const isInWebAppiOS = window.navigator.standalone === true;
      const isInWebAppChrome = window.matchMedia('(display-mode: standalone)').matches;
      return isInWebAppiOS || isInWebAppChrome;
    },
    closedAppPopup() {
      return window.localStorage.getItem('closedAppPopup');
    },
    shouldShowaddToHomeScreenPopup() {
      return this.isMobileDevice && !this.isInStandaloneMode && !this.closedAppPopup;
    },
    isMobileDevice() {
      let check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    }
  },
  data() {
    return {
      addToHomeScreenPopup: false
    };
  },
  methods: {
    close() {
      this.addToHomeScreenPopup = false;
      window.localStorage.setItem('closedAppPopup', true);
    }
  }
};
</script>

<style>
i.material-icons.android {
  vertical-align: bottom;
}
</style>

<style lang="scss" scoped>
.add-to-homescreen {
  background-color: #ffffff;
  border-top: 1px solid #eeeeee;
  bottom: 30px;
  position: fixed;
  padding-bottom: 12px;
  width: 100%;
  z-index: 2;
}

.add-to-homescreen > div {
  @media (max-width: 576px) {
    max-width: 400px;
  }

  max-width: 520px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.add-to-homescreen > div > div {
  @media (max-width: 576px) {
    max-width: 300px;
  }
  max-width: 440px;
  padding-left: 10px;
  float: left;
}

.add-to-homescreen > div > img {
  width: 44px;
  float: left;
}

.add-to-homescreen > div > div img {
  float: none;
  height: 16px;
  margin-top: -3px;
}

h1 {
  font-size: var(--subtitle-size);
  display: inline;
  margin: 0;
  text-transform: capitalize;
}

p {
  margin: 0;
  padding: 0;
}

a {
  color: #444444;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.add {
  padding: 5px 10px;
  border: 1px solid #eeeeee;
  display: block;
  width: 200px;
  text-align: center;
  margin-top: 5px;
  text-decoration: none;
}
</style>
